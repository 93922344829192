<template>
    <div>
        <page-title :heading="'Webhooks'" :subheading="'Webhooks'" :show-add-button="true" :permission-add="true"
                    :icon=icon ></page-title>
        <div class="app-main__inner">
          <WebhooksComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "@/Layout/Components/PageTitle.vue";
    import WebhooksComponent from "../../../../components/settings/moduleSettings/webhooks/WebhooksComponent";

    export default {
        components: {
          WebhooksComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>